import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppContext } from 'AppContextProvider';
import axiosInstance from 'utils/axios.config';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function OwnerInfoLists({ handleOwnerClose, ownerOpen }) {
  const { setOwnerInfoLists, ownerInfoLists } = useAppContext();
  const {
    building_ownername,
    building_ownerphone,
    subscription_amount_bdt,
    building_ownerstatus,
    subscription_currency,
    building_ownerregtype,
    building_owneraddress,
    building_owner_subs_type
  } = ownerInfoLists;

  useEffect(() => {
    axiosInstance
      .get('https://api.hellokompass.com/ownrinfo')
      .then((res) => {
        const data = res.data;
        if (data.code === 200) {
          setOwnerInfoLists(data.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            console.error('400 Error: Bad Request', error.response.data);
          } else {
            console.error(`Error: ${error.response.status}`, error.response.data);
          }
        } else if (error.request) {
          console.error('Error: No response received', error.request);
        } else {
          console.error('Error:', error.message);
        }
      });
  }, [setOwnerInfoLists]);

  return (
    <Box>
      <Modal open={ownerOpen} onClose={handleOwnerClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ textAlign: 'left', pr: '4px', pb: '5px', borderBottom: '1px solid #f1f1f1' }}>
            <Typography variant="p" sx={{ color: '#333', fontSize: '18px', fontFamily: 'poppins', fontWeight: 'bold' }}>
              Owner Info
            </Typography>
          </Box>

          <Box sx={{ pt: '10px' }}>
            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium' }}>
              Building Owner Name : {building_ownername}
            </Typography>
            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium', mt: 1 }}>
              Building Owner Phone : {building_ownerphone}
            </Typography>
            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium', mt: 1 }}>
              Building Owner Address : {building_owneraddress}
            </Typography>
            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium', mt: 1 }}>
              Subscription Amount : {subscription_amount_bdt} {subscription_currency}
            </Typography>

            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium', mt: 1 }}>
              Building Owner Registration type : {building_ownerregtype}
            </Typography>
            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium', mt: 1 }}>
              Building Owner Subscription Type : {building_owner_subs_type}
            </Typography>
            <Typography sx={{ color: '#333', fontSize: '14px', fontFamily: 'poppins', fontWeight: 'medium', mt: 1 }}>
              Building Owner Status : {building_ownerstatus}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
