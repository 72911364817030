import React from 'react';

import { Modal, Typography, Button, Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function FavoriteDeleteModal({ deleted, handleCloseDelete, DeleteFavoritePlace, glocid }) {
  return (
    <div>
      <Modal open={deleted} onClose={handleCloseDelete}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h2" sx={{ fontSize: '25px', fontWeight: 'bolder', font: 'poppins' }}>
              Are You Sure?
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ mt: 2, fontSize: '12px', fontWeight: 'medium', font: 'poppins', textAlign: 'center' }}>
              Do you want delete this?After deleting you can't get back.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
            <Button
            onClick={handleCloseDelete}
              sx={{
                backgroundColor: '#ccc',
                color: '#333',
                font: 'poppins',
                fontSize: '12px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#ccc',
                  color: '#333'
                }
              }}
            >
              cancel
            </Button>

            <Button
              onClick={() => DeleteFavoritePlace(glocid)}
              sx={{
                backgroundColor: '#FF0000',
                color: '#fff',
                font: 'poppins',
                fontSize: '12px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#FF0000',
                  color: '#fff'
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
