import React, { useState } from 'react';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';

import PropertyInfoList from './PropertyInfoLists';

import OwnerInfoLists from './OwnerInfoLists';

export default function OwnerList() {
  const [ownerOpen, setOwnerOpen] = useState(false);
  const handleOwnerOpen = () => setOwnerOpen(true);
  const handleOwnerClose = () => setOwnerOpen(false);

  return (
    <Box sx={{ mt: '20px' }}>
      <MainCard>
        <Box>
          <Box sx={{ textAlign: 'right', pr: '4px', pb: '20px', cursor: 'pointer' }} onClick={handleOwnerOpen}>
            <Typography
              variant="p"
              sx={{ color: '#333', text: '18px', fontFamily: 'poppins', fontWeight: 'bold', textDecoration: 'underline' }}
            >
              Owner Info
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <PropertyInfoList></PropertyInfoList>
        </Box>
        <OwnerInfoLists ownerOpen={ownerOpen} handleOwnerClose={handleOwnerClose}></OwnerInfoLists>
      </MainCard>
    </Box>
  );
}
