import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';


// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const Connect= Loadable(lazy(() => import('pages/connect/Connect')));
// const Cancel = Loadable(lazy(() => import('pages/cancel/Cancel')));
const EmailVerification = Loadable(lazy(() => import('pages/emailVerification/EmailVerification')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'connect',
      element: <Connect />
    },
    // {
    //   path: 'cancel',
    //   element: <Cancel />
    // },
    {
      path: 'email-verification',
      element: <EmailVerification />
    }
  ]
};

export default LoginRoutes;
