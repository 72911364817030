import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Input,
  InputLabel
} from '@mui/material';

import MainCard from 'components/MainCard';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'AppContextProvider';
import axiosInstance from 'utils/axios.config';
import { toast } from 'react-toastify';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};



export default function AddPropertyOwnerModal({handleClosePropertyOwner,openPropertyOwner}) {
  const { agentProfile } = useAppContext();
  const { agcode, agent_code } = agentProfile;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  //   {
  //     resolver: yupResolver(loanPage)
  //   }

  const onSubmit = (data) => {
    console.log('data' , data)
    axiosInstance.post('https://api.hellokompass.com/create-bowner', data).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.message);
        reset();
        handleClosePropertyOwner()
        navigate('/property/propertyList/properties');
      } else if (res.data.code === 400) {
        toast.failed(res.data.message);
      } else {
        <></>;
      }
    });
  };

  return (
    <Modal
    open={openPropertyOwner}
    onClose={handleClosePropertyOwner}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
      <Box sx={style}>
       
            <form onSubmit={handleSubmit(onSubmit)}>
             
<Box>
<Box
  sx={{
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: '23px',
    color: '#0C1E21',
    mb: '20px',
    pb: '4px',
    borderBottom: 1,
    borderColor: '#f1f1f1'
  }}
>
  Add Property Owner
</Box>

<Box>
<Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
  Property Owner Name
</Typography>
<TextField
  name="pwname"
  {...register('pwname', { required: true })}
  id="outlined-textarea"
  placeholder="Property Owner Name"
  size="medium"
  sx={{ width: '100%' }}
/>
</Box>

<Box sx={{mt:2}}>
<Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
  Property Owner Phone
</Typography>

<TextField
  name="pwphone"
  {...register('pwphone', { required: true })}
  id="outlined-textarea"
  placeholder="Property Owner Phone"
  size="medium"
  sx={{ width: '100%' }}
/>
</Box>

<Box sx={{mt:2}}>
<Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
  Property Owner Email
</Typography>
<TextField
  name="pwpemail"
  {...register('pwpemail', { required: true })}
  id="outlined-textarea"
  placeholder="Property Owner Email"
  size="medium"
  sx={{ width: '100%' }}
/>
</Box>

<Box sx={{mt:2}}>
<Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
  Property Owner Address
</Typography>
<TextField
  name="pwaddress"
  {...register('pwaddress', { required: true })}
  id="outlined-textarea"
  placeholder="Property Owner Address"
  size="medium"
  sx={{ width: '100%' }}
/>
</Box>

{/* hidden */}
<Box hidden>
  <TextField
    name="pwsubscription"
    {...register('pwsubscription', { required: true })}
    id="outlined-textarea"
    value="monthly"
    size="medium"
    sx={{ width: '100%' }}
  />
</Box>

<Box hidden>
  <TextField
    name="pwsubcurreny"
    {...register('pwsubcurreny', { required: true })}
    id="outlined-textarea"
    value="BDT"
    size="medium"
    sx={{ width: '100%' }}
  />
</Box>

<Box hidden>
  <TextField
    name="pwsubcurreny_bdt"
    {...register('pwsubcurreny_bdt', { required: true })}
    id="outlined-textarea"
    value="50.00"
    size="medium"
    sx={{ width: '100%' }}
  />
</Box>


<Box hidden>
  <TextField
    name="pwsubcurreny_usd"
    {...register('pwsubcurreny_usd', { required: true })}
    id="outlined-textarea"
    value="00.00"
    size="medium"
    sx={{ width: '100%' }}
  />
</Box>
<Box hidden>
  <TextField
    name="pwregtype"
    {...register('pwregtype', { required: true })}
    id="outlined-textarea"
    value="OnboardAgent"
    size="medium"
    sx={{ width: '100%' }}
  />
</Box>
{agent_code && (
  <Box hidden>
    <TextField
      name="agent_code"
      {...register('agent_code', { required: true })}
      id="outlined-textarea"
      value={agent_code}
      size="medium"
      sx={{ width: '100%' }}
    />
  </Box>
)}


{agcode && (
  <Box hidden>
    <TextField
      name="agency_code"
      {...register('agency_code', { required: true })}
      id="outlined-textarea"
      value={agcode}
      size="medium"
      sx={{ width: '100%' }}
    />
  </Box>
)}
</Box>

<Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
<Button
  variant="contained"
  type="submit"
  sx={{
    px: 10,
    backgroundColor: '#0C1E21',
    '&:hover': {
      backgroundColor: '#0C1E21'
    }
  }}
>
  submit
</Button>
</Box>
            </form>
        
      </Box>
      </Modal>
  );
}
