import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Input,
  InputLabel
} from '@mui/material';

import MainCard from 'components/MainCard';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'AppContextProvider';
import axiosInstance from 'utils/axios.config';
import { toast } from 'react-toastify';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import propertyImage from '../../../../assets/images/images/property_image.jpg';

export default function AddProOwner() {
  const { propertyAgent } = useAppContext();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  //   {
  //     resolver: yupResolver(loanPage)
  //   }

  const onSubmit = (data) => {
    // axiosInstance.post('https://api.hellokompass.com/create-propertyinfo', data).then((res) => {
    //   if (res.data.code === 200) {
    //     toast.success(res.data.message);
    //     reset();
    //     navigate('/property/propertyList/properties');
    //   } else if (res.data.code === 400) {
    //     toast.failed(res.data.message);
    //   } else {
    //     <></>;
    //   }
    // });
  };
  return (
    <MainCard>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Box
                  sx={{
                    fontWeight: 'bold',
                    fontFamily: 'Poppins',
                    fontSize: '23px',
                    color: '#0C1E21',
                    mb: '20px',
                    pb: '4px',
                    borderBottom: 1,
                    borderColor: '#f1f1f1'
                  }}
                >
                  Add Property List
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="p" component="div" sx={{ mb: 1 }}>
                      Select Property Owner
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        {...register('powner', { required: true })}
                        name="powner"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="medium"
                      >
                        <MenuItem>
                          <InputLabel htmlFor="outlined-adornment">Select Property Owner</InputLabel>
                        </MenuItem>
                        {propertyAgent.map((propertyA) => (
                          <MenuItem value={propertyA.building_ownercode} key={propertyA.building_ownercode}>
                            {propertyA.building_ownername}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.bank_code?.message}</Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="p" component="div" sx={{ mb: 1 }}>
                      Property Name
                    </Typography>
                    <TextField
                      name="property_name"
                      {...register('property_name', { required: true })}
                      id="outlined-textarea"
                      placeholder="Property Name"
                      size="medium"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="p" component="div" sx={{ mb: 1 }}>
                      Property Floor
                    </Typography>
                    <TextField
                      type="number"
                      name="property_floor"
                      {...register('property_floor', { required: true })}
                      id="outlined-textarea"
                      placeholder="Property Floor"
                      size="medium"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="p" component="div" sx={{ mb: 1 }}>
                      Property Floor Unit
                    </Typography>
                    <TextField
                      type="number"
                      name="property_floorunit"
                      {...register('property_floorunit', { required: true })}
                      id="outlined-textarea"
                      placeholder="Property Floor Unit"
                      size="medium"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="p" component="div" sx={{ mb: 1 }}>
                      Select Property parking
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        {...register('property_parking', { required: true })}
                        name="property_parking"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="medium"
                      >
                        <MenuItem>
                          <InputLabel htmlFor="outlined-adornment">Select Property Parking</InputLabel>
                        </MenuItem>

                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                      <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.bank_code?.message}</Typography>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="p" component="div" sx={{ mb: 1 }}>
                      Property Owner Address
                    </Typography>
                    <TextField
                      name="property_address"
                      {...register('property_address', { required: true })}
                      id="outlined-textarea"
                      placeholder="Property Owner Address"
                      size="medium"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    px: 10,
                    backgroundColor: '#0C1E21',
                    '&:hover': {
                      backgroundColor: '#0C1E21'
                    }
                  }}
                >
                  submit
                </Button>
              </Box>
            </form>
          </Grid>
          <Grid item xs={6}>
            <img src={propertyImage} alt="propertyImage" style={{ width: '100%', height: '100%' }}></img>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
}
