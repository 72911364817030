import React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function ApartmentInfoList({ apartmentList}) {
  console.log('apartmentList', apartmentList)
    const { apartment_name
      , apartment_level
      , apartment_address, aparment_scode
      , aparment_qcdfile
      , apartment_status } =
      apartmentList;
  return (
    <StyledTableRow>
    <StyledTableCell
      align="left"
      sx={{
        fontSize: '14px',
        fontWeight: 'medium',
        fontFamily: 'Poppins, sans-serif',
        color: '#0c1e21'
      }}
    >
      {apartment_name}
    </StyledTableCell>
    <StyledTableCell
      align="left"
      sx={{
        fontSize: '14px',
        fontWeight: 'medium',
        fontFamily: 'Poppins, sans-serif',
        color: '#0c1e21'
      }}
    >
      {apartment_level}
    </StyledTableCell>
    <StyledTableCell
      align="left"
      sx={{
        fontSize: '14px',
        fontWeight: 'medium',
        fontFamily: 'Poppins, sans-serif',
        color: '#0c1e21'
      }}
    >
      {apartment_address}
    </StyledTableCell>
    <StyledTableCell
      align="left"
      sx={{
        fontSize: '14px',
        fontWeight: 'medium',
        fontFamily: 'Poppins, sans-serif',
        color: '#0c1e21'
      }}
    >
      {aparment_scode}
    </StyledTableCell>
    <StyledTableCell
      align="left"
      sx={{
        fontSize: '14px',
        fontWeight: 'medium',
        fontFamily: 'Poppins, sans-serif',
        color: '#0c1e21'
      }}
    >
      {aparment_qcdfile}
    </StyledTableCell>
    <StyledTableCell
      align="left"
      sx={{
        fontSize: '14px',
        fontWeight: 'medium',
        fontFamily: 'Poppins, sans-serif',
        color: '#0c1e21'
      }}
    >
      {apartment_status}
    </StyledTableCell>
 
  </StyledTableRow>
  )
}
