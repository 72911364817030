import React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function ListOfProperty({ listPro }) {
  const { property_name, property_floor, property_parking, property_qrxcode, property_status, property_unit, property_address } = listPro;
  return (
    <StyledTableRow>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_name}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_floor}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_unit}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_parking}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_address}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        // sx={{
        //   fontSize: '14px',
        //   fontWeight: 'medium',
        //   fontFamily: 'Poppins, sans-serif',
        //   color: '#0c1e21'
        // }}
      >
        <img src={property_qrxcode} alt="" />
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_status}
      </StyledTableCell>
    </StyledTableRow>
  );
}
