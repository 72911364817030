import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import PropertyLoader from 'loader/PropertyLoader';
import { useAppContext } from 'AppContextProvider';
import axiosInstance from 'utils/axios.config';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import PropertyInfoList from './PropertyInfoList';
import { useParams } from 'react-router-dom';
import ApartmentInfoList from './ApartmentInfoList';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#0C1E21',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1600,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function ApartmentInfoLists() {
  const { building_id } = useParams();

  const { loading } = useAppContext();
  const [apartmentLists, setApartmentLists] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`https://api.hellokompass.com/apartmentlist?building_id=${building_id}`)
      .then((res) => {
        const data = res.data;
        if (data.code === 200) {
          setApartmentLists(data.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            console.error('400 Error: Bad Request', error.response.data);
          } else {
            console.error(`Error: ${error.response.status}`, error.response.data);
          }
        } else if (error.request) {
          console.error('Error: No response received', error.request);
        } else {
          console.error('Error:', error.message);
        }
      });
  }, []);

  return (
    <Box>
      {loading ? (
        <>
          <PropertyLoader></PropertyLoader>
        </>
      ) : (
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Apartment Name</StyledTableCell>
                  <StyledTableCell align="left">Apartment Level</StyledTableCell>
                  <StyledTableCell align="left">Apartment address</StyledTableCell>
                  <StyledTableCell align="left">Apartment Code</StyledTableCell>
                  <StyledTableCell align="left">Apartment qcd file</StyledTableCell>
                  <StyledTableCell align="left">Apartment Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apartmentLists?.map((apartmentList) => (
                  <ApartmentInfoList apartmentList={apartmentList} key={apartmentList.apartment_id}></ApartmentInfoList>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
