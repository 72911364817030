import React from 'react';
import { Box, Button, Typography, Modal, TextField, Select, FormControl, MenuItem } from '@mui/material';
import axiosInstance from 'utils/axios.config';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function FavoritePlaceModal({ open, handleClose, favoritePlace, updateFavoritePlace }) {
  const { loc_address, loc_name, loc_status, loc_type, glocid } = favoritePlace;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    data.glocid = glocid;
    console.log('Form data before API call:', data);

    axiosInstance.post('https://api.hellokompass.com/fav/updateplace', data).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.message);
        reset();
        handleClose();
        
        const updatedPlace = {
          glocid,
          loc_name: data.lcname,
          loc_status: data.lcstatus,
          loc_type: data.lctype,
          loc_address: data.lcaddress
        };
        updateFavoritePlace(updatedPlace);
      } else if (res.data.code === 400) {
        toast.failed(res.data.message);
      } else {
        <></>;
      }
    });
  };
  return (
    <Box sx={{ border: 0 }}>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ border: 0 }}>
          <Modal open={open} onClose={handleClose}>
            <Box sx={{ ...style }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mt: 0 }}>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Poppins',
                      fontSize: '23px',
                      color: '#0C1E21',
                      mb: '20px',
                      pb: '4px',
                      borderBottom: 1,
                      borderColor: '#f1f1f1'
                    }}
                  >
                    Update Favorite Place
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
                      Location ID
                    </Typography>
                    {glocid && <TextField name="plid" {...register('plid')} size="medium" sx={{ width: '100%' }} value={glocid} />}
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
                      Location Name
                    </Typography>
                    {loc_name && (
                      <TextField
                        name="lcname"
                        {...register('lcname')}
                        // placeholder="Enter Location Name"
                        size="medium"
                        sx={{ width: '100%' }}
                        defaultValue={loc_name}
                      />
                    )}
                  </Box>

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1, pt: 1 }}>
                      Location Address
                    </Typography>
                    {loc_address && (
                      <TextField
                        name="lcaddress"
                        {...register('lcaddress')}
                        // placeholder="Enter Location Address"
                        size="medium"
                        sx={{ width: '100%' }}
                        defaultValue={loc_address}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1, pt: 1 }}>
                      Location Type
                    </Typography>
                    {loc_type && (
                      <FormControl fullWidth>
                        <Controller
                          name="lctype"
                          control={control}
                          defaultValue={loc_type}
                          render={({ field }) => (
                            <Select {...field} displayEmpty inputProps={{ 'aria-label': 'Without label' }} size="medium">
                              <MenuItem value="public">Public</MenuItem>
                              <MenuItem value="private">Private</MenuItem>
                            </Select>
                          )}
                        />
                        <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.lctype?.message}</Typography>
                      </FormControl>
                    )}
                  </Box>

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1, pt: 1 }}>
                      Location Status
                    </Typography>
                    {loc_status && (
                      <FormControl fullWidth>
                        <Controller
                          name="lcstatus"
                          control={control}
                          defaultValue={loc_status}
                          render={({ field }) => (
                            <Select {...field} displayEmpty inputProps={{ 'aria-label': 'Without label' }} size="medium">
                              <MenuItem value="active">Active</MenuItem>
                              <MenuItem value="deactive">Deactive</MenuItem>
                            </Select>
                          )}
                        />
                        <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.lctype?.message}</Typography>
                      </FormControl>
                    )}
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width:'100%',
                      font: 'poppins',
                      fontWeight: 'bold',
                      borderRadius: 0,
                      backgroundColor: '#12a9b2',
                      '&:hover': {
                        backgroundColor: '#12a9b2'
                      }
                    }}
                  >
                    Update Favorite Place
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
        </Box>
      </Modal>
    </Box>
  );
}
