import React, { useEffect, useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Paper,
  styled,
  TableBody,
  Button
} from '@mui/material';
import MainCard from 'components/MainCard';
import PropertyLoader from 'loader/PropertyLoader';
import { useAppContext } from 'AppContextProvider';
import FavoritePlaceList from './FavoritePlaceList';
import axiosInstance from 'utils/axios.config';
import AddFavoriteModal from 'components/modal/favoritePlaceModal/AddFavoriteModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#12a9b2',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export default function FavoritePlace() {
  const { loading } = useAppContext();

  const [favoritePlaces, setFavoritePlaces] = useState([]);

  useEffect(() => {
    fetchFavoritePlaces();
  }, []);

  const fetchFavoritePlaces = async () => {
    try {
      const res = await axiosInstance.get('https://api.hellokompass.com/fav/placelist');
      const data = res.data;
      if (data.code === 200) {
        setFavoritePlaces(data?.data);
      } else {
        console.error('Unexpected response structure:', data);
      }
    } catch (error) {
      console.error('Error fetching favorite places:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <MainCard>
        <Box sx={{ display: 'flex', justifyContent: 'end' }} onClick={handleOpen}>
          <Button
            sx={{
              backgroundColor: '#12a9b2',
              color: '#fff',
              px: '50px',
              py: '8px',
              mb: '20px',
              borderRadius: '0px',
              '&:hover': {
                backgroundColor: '#12a9b2',
                color: '#fff'
              }
            }}
          >
            + Favorite Place
          </Button>
        </Box>

        {loading ? (
          <>
            <PropertyLoader></PropertyLoader>
          </>
        ) : (
          <Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Location Name</StyledTableCell>
                    <StyledTableCell align="left">Location Address</StyledTableCell>
                    <StyledTableCell align="left">Location Type</StyledTableCell>
                    <StyledTableCell align="left">Location Status</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {favoritePlaces?.map((favoritePlace) => (
                    <FavoritePlaceList
                      favoritePlace={favoritePlace}
                      favoritePlaces={favoritePlaces}
                      setFavoritePlaces={setFavoritePlaces}
                      key={favoritePlace.glocid}
                    ></FavoritePlaceList>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </MainCard>
      <AddFavoriteModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        fetchFavoritePlaces={fetchFavoritePlaces}
      ></AddFavoriteModal>
    </Box>
  );
}
