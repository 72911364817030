import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Edit from 'components/svg/Edit';
import Delete from 'components/svg/Delete';
import axiosInstance from 'utils/axios.config';
import FavoritePlaceModal from 'components/modal/favoritePlaceModal/FavoritePlaceModal';
import FavoriteDeleteModal from 'components/modal/favoritePlaceModal/FavoriteDeleteModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function FavoritePlaceList({ favoritePlace, favoritePlaces, setFavoritePlaces,refreshFavoritePlaces }) {
  const { loc_address, loc_name, loc_status, loc_type, glocid } = favoritePlace;
  // console.log('glocid', glocid);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [deleted,setDeleted] = useState(false);
  const handleOpenDelete = () => setDeleted(true);
  const handleCloseDelete = () => setDeleted(false);

  const updateFavoritePlace = (updatedPlace) => {
    setFavoritePlaces((prevPlaces) =>
      prevPlaces.map((place) =>
        place.glocid === updatedPlace.glocid ? { ...place, ...updatedPlace } : place
      )
    );
    console.log("updatedPlace in updateFavoritePlace:", updatedPlace); 
  };

  const DeleteFavoritePlace = (glocid) => {
    console.log('Deleted successfully:', glocid);
    axiosInstance
      .delete(`https://api.hellokompass.com/fav/deleteplace/${glocid}`)
      .then(() => {
        setFavoritePlaces(favoritePlaces.filter((place) => place.glocid !== glocid));
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <StyledTableRow>
    
        <StyledTableCell
          align="left"
          sx={{
            fontSize: '14px',
            fontWeight: 'medium',
            fontFamily: 'Poppins, sans-serif',
            color: '#0c1e21'
          }}
        >
          {loc_name}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            fontSize: '14px',
            fontWeight: 'medium',
            fontFamily: 'Poppins, sans-serif',
            color: '#0c1e21'
          }}
        >
          {loc_address}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            fontSize: '14px',
            fontWeight: 'medium',
            fontFamily: 'Poppins, sans-serif',
            color: '#0c1e21'
          }}
        >
          {loc_status}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            fontSize: '14px',
            fontWeight: 'medium',
            fontFamily: 'Poppins, sans-serif',
            color: '#0c1e21'
          }}
        >
          {loc_type}
        </StyledTableCell>

        <StyledTableCell
          align="left"
          sx={{
            fontSize: '14px',
            fontWeight: 'medium',
            fontFamily: 'Poppins, sans-serif',
            color: '#0c1e21'
          }}
        >
          <Box sx={{ display: 'flex ', gap: 1 }}>
            <Button onClick={handleOpen} sx={{ p: 0, m: 0 }}>
              {' '}
              <Box sx={{ cursor: 'pointer' }}>
                <Edit></Edit>
              </Box>
            </Button>
            <Box sx={{ cursor: 'pointer' }}>
            
                <Button onClick={handleOpenDelete}>  <Delete></Delete></Button>
            </Box>
          </Box>
        </StyledTableCell>
      </StyledTableRow>

      <FavoritePlaceModal open={open} handleClose={handleClose} handleOpen={handleOpen} favoritePlace={favoritePlace} refreshFavoritePlaces={refreshFavoritePlaces} updateFavoritePlace={updateFavoritePlace}/>
      <FavoriteDeleteModal deleted={deleted} handleCloseDelete={handleCloseDelete} handleOpenDelete={handleOpenDelete} DeleteFavoritePlace={DeleteFavoritePlace} glocid={glocid}/>
    </>
  );
}
// onClick={() => DeleteFavoritePlace(glocid)}