import React, { useEffect } from 'react'
import { Box } from '@mui/material';
import PropertyLoader from 'loader/PropertyLoader';
import { useAppContext } from 'AppContextProvider';
import axiosInstance from 'utils/axios.config';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useState } from 'react';
import PropertyInfoList from './PropertyInfoList';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#0C1E21',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));


export default function PropertyInfoLists() {
  const { loading,ownerInfoLists ,propertyLists,setPropertyLists} = useAppContext();

  const {building_ownercode} = ownerInfoLists;

   useEffect(() => {
     axiosInstance
       .get(`https://api.hellokompass.com/allpropertyinfobyowner?pownercode=${building_ownercode}`)
       .then((res) => {
         const data = res.data;
         if (data.code === 200) {
          setPropertyLists(data.data);
         }
       })
       .catch((error) => {
         if (error.response) {
           if (error.response.status === 400) {
             console.error('400 Error: Bad Request', error.response.data);
           } else {
             console.error(`Error: ${error.response.status}`, error.response.data);
           }
         } else if (error.request) {
           console.error('Error: No response received', error.request);
         } else {
           console.error('Error:', error.message);
         }
       });
   }, [building_ownercode]);

   

  return (
 
 <Box>
           {loading ? (
        <>
          <PropertyLoader></PropertyLoader>
        </>
      ) : (
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Property Name</StyledTableCell>
                  <StyledTableCell align="left">Property username
                  </StyledTableCell>
                  <StyledTableCell align="left">Property address</StyledTableCell>
                  <StyledTableCell align="left">Property Floors</StyledTableCell>
                  <StyledTableCell align="left">Property parking
                  </StyledTableCell>
                  <StyledTableCell align="left">Property Qr code
                  </StyledTableCell>
                  <StyledTableCell align="left">Property Status</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
            
                </TableRow>
              </TableHead>
                <TableBody>
                {propertyLists?.map((propertyList) => (
                  <PropertyInfoList propertyList={propertyList} key={propertyList.owner_id
                    }></PropertyInfoList>
                ))}
              </TableBody> 
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>

  )
}
