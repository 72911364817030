import React from 'react';
import { Box, Skeleton } from '@mui/material';

export default function PropertyLoader() {
  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
        <Skeleton width="250px" height={120}  sx={{ borderRadius: '0' }} />
      </Box>
      <Box sx={{ display: 'flex',mt:'-39px' }}>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80} style={{ backgroundColor: '#f1f1f1' }}  sx={{ borderRadius: '0' }}/>
      </Box>
      <Box sx={{ display: 'flex',mt:'-32px' }}>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
        <Skeleton width="250px" height={80}  sx={{ borderRadius: '0' }}/>
      </Box>
    </Box>
  );
}
