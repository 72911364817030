import React, { useState } from 'react';
import { Box, Toolbar, Typography, Divider, Grid, Button, FormHelperText, Input } from '@mui/material';
import MainCard from 'components/MainCard';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axiosInstance from 'utils/axios.config';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
// import Captcha from 'components/Captcha/Captcha';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function PasswordChanged({ handleClosePassword, handleOpenPassword, password }) {
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    axiosInstance.post('https://api.hellokompass.com/user/changepassword', data).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.message);
        handleClosePassword();
        reset();
      } else if (res.data.code === 400) {
        toast.failed(res.data.message);
        reset();
      } else {
        <></>;
      }
    });
  };

  const handleButtonViewProfile = () => {
    navigate('/profile/viewProfile');
  };

  return (
    <div>

      <Modal open={password} onClose={handleClosePassword} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ flexGrow: 1 }}>

              <Typography variant="p" component="div" sx={{ color:'#333' , fontSize:'24px',font:'poppins', fontWeight:'bold' , borderBottom:'1px solid #f1f1f1' , pb:1}}>
                Change Password
              </Typography>

          </Box>
          {/* <Divider variant="middle" sx={{ my: 3 }} /> */}
          <Box>
        
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                
                      <Box sx={{mt:2}}>
                        <FormHelperText>
                          <Typography variant="h6" component="h2"   sx={{ color:'#333' , fontSize:'14px',font:'poppins', fontWeight:'bold'}}>
                            Old Password
                          </Typography>
                        </FormHelperText>
                        <Input
                          sx={{ mt: 1, '&:focus': { borderBottomColor: '#12A92B' } }}
                          fullWidth
                          type="password"
                          name="old_password"
                          displayEmpty
                          {...register('old_password', { required: true })}
                        />
                      </Box>
                 
                      <Box sx={{mt:2}}>
                        <FormHelperText>
                          <Typography variant="h6" component="h2" sx={{ color:'#333' , fontSize:'14px',font:'poppins', fontWeight:'bold'}}>
                            New Password
                          </Typography>
                        </FormHelperText>
                        <Input
                          sx={{ mt: 1, '&:focus': { borderBottomColor: '#12A92B' } }}
                          name="new_password"
                          fullWidth
                          type="password"
                          displayEmpty
                          {...register('new_password', { required: true })}
                        />
                      </Box>
           
                
                 

                  <Box sx={{ display: 'flex', justifyContent: 'start',gap:2, mt: 4 }}>
                    <Box>
                      <Button
                        onClick={handleButtonViewProfile}
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: '#12A9B2', px:4,borderRadius:'0', fontSize:"14px",fontWeight:'bold', '&:hover': { backgroundColor: '#FF0000' } }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: '#12A9B2',    px:4,borderRadius:'0', fontSize:"14px",fontWeight:'bold', '&:hover': { backgroundColor: '#12A9B2' } }}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
       
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
