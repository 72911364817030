import React from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from 'utils/axios.config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Typography, Button, Grid, FormControl, Select, MenuItem, TextField, InputLabel } from '@mui/material';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function AddFavoriteModal({ open, handleClose, fetchFavoritePlaces}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    axiosInstance.post('https://api.hellokompass.com/fav/addplace', data).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.message);
        fetchFavoritePlaces()
        console.log(data)
        reset();
        handleClose();
      } else if (res.data.code === 400) {
        toast.failed(res.data.message);
      } else {
        <></>;
      }
    });
  };
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mt: 0 }}>
            <Box
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Poppins',
                fontSize: '23px',
                color: '#0C1E21',
                mb: '20px',
                pb: '4px',
                borderBottom: 1,
                borderColor: '#f1f1f1'
              }}
            >
              Add Favorite Place
            </Box>

            <Box>
              <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1 }}>
                Location Name
              </Typography>
              <TextField
                name="lcname"
                {...register('lcname', { required: true })}
                placeholder="Enter Location Name"
                size="medium"
                sx={{ width: '100%' }}
              />
            </Box>

            <Box>
              <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1,pt:2 }}>
                Location Address
              </Typography>
              <TextField
                name="lcaddress"
                {...register('lcaddress', { required: true })}
                placeholder="Enter Location Address"
                size="medium"
                sx={{ width: '100%' }}
              />
            </Box>

            <Box>
              <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: 1,pt:2 }}>
                Location Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  name="lctype"
                  {...register('lctype', { required: true })}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  size="medium"
                >
                  <MenuItem>
                    <InputLabel htmlFor="outlined-adornment">Select Location Type</InputLabel>
                  </MenuItem>

                  <MenuItem value="public">Public</MenuItem>
                  <MenuItem value="private">Private</MenuItem>
                </Select>
                <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.bank_code?.message}</Typography>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                width: '100%',
                font: 'poppins',
                fontWeight: 'bold',
                borderRadius: 0,
                backgroundColor: '#12a9b2',
                '&:hover': {
                  backgroundColor: '#12a9b2'
                }
              }}
            >
              Favorite Place
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
