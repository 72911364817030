import React, { useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import PropertyAction from 'components/svg/PropertyAction';
import { useNavigate } from "react-router";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));


export default function PropertyInfoList({ propertyList }) {
  const navigate = useNavigate();
  const { property_name, property_parking, property_address, property_code, property_status, property_username, property_floor,building_id } =
    propertyList;

const handleApartmentOpen = () => {
  navigate(`/property/ownerList/apartmentList/${building_id}`)
}



  return (
    <StyledTableRow>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_name}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_username}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_address}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_floor}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_parking}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_code}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21'
        }}
      >
        {property_status}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          fontSize: '14px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
          color: '#0c1e21',
          cursor:'pointer'
        }}
        onClick={handleApartmentOpen}
      >
        <PropertyAction></PropertyAction>
      </StyledTableCell>

    </StyledTableRow>
  );
}
